<template>
  <b-form>
    <div class="d-flex flex-row flex-wrap">
      <div
        v-if="!hiddenInput.headQuarter"
        class="col-12 px-2 mb-2"
        :class="{ [`col-lg-${colHeadquarter}`]: true }"
      >
        <label class="font-weight-bold ft-s-14" for="headQuarter">{{
          labelHeadquarter
        }}</label>

        <v-select
          id="headQuarter"
          v-model="headQuarter"
          :options="headQuarters"
          :disabled="disabledInput.headQuarter"
          :loading="isFetchingHeadQuarter"
          :clearable="headQuarter.id !== null"
          @input="onInput($event, 'headQuarter')"
        >
          <template #no-options>
            {{ `ไม่พบข้อมูล${labelHeadquarter}` }}
          </template>
        </v-select>

        <small
          v-if="
            invalid &&
            validation.filterMaster.mHeadquarterId &&
            !validation.filterMaster.mHeadquarterId.required
          "
          class="text-danger"
        >
          กรุณาเลือก{{ labelHeadquarter }}
        </small>
      </div>

      <div
        v-if="!hiddenInput.division"
        class="col-12 px-2 mb-2"
        :class="{ [`col-lg-${colDivision}`]: true }"
      >
        <label class="font-weight-bold ft-s-14" for="division">{{
          labelDivision
        }}</label>

        <v-select
          id="division"
          v-model="division"
          :options="divisions"
          :disabled="disabledInput.division"
          :loading="isFetchingDivision"
          :clearable="division.id !== null"
          @input="onInput($event, 'division')"
        >
          <template #no-options>
            {{ `ไม่พบข้อมูล${labelDivision}` }}
          </template>
        </v-select>

        <small
          v-if="
            invalid &&
            validation.filterMaster.mDivisionId &&
            !validation.filterMaster.mDivisionId.required
          "
          class="text-danger"
        >
          กรุณาเลือก{{ labelDivision }}
        </small>
      </div>

      <div
        v-if="!hiddenInput.station"
        class="col-12 px-2 mb-2"
        :class="{ [`col-lg-${colStation}`]: true }"
      >
        <label class="font-weight-bold ft-s-14" for="station">{{
          labelStation
        }}</label>

        <v-select
          id="station"
          v-model="station"
          :options="stations"
          :disabled="disabledInput.station"
          :loading="isFetchingStation"
          :clearable="station.id !== null"
          @input="onInput($event, 'station')"
        >
          <template #no-options>
            {{ `ไม่พบข้อมูล${labelStation}` }}
          </template>
        </v-select>

        <small
          v-if="
            invalid &&
            validation.filterMaster.mStationId &&
            !validation.filterMaster.mStationId.required
          "
          class="text-danger"
        >
          กรุณาเลือก{{ labelStation }}
        </small>
      </div>

      <div
        v-if="!hiddenInput.school"
        class="col-12 px-2 mb-2"
        :class="{ [`col-lg-${colSchool}`]: true }"
      >
        <label class="font-weight-bold ft-s-14" for="school">{{
          labelSchool
        }}</label>

        <v-select
          id="school"
          v-model="school"
          :options="schools"
          :disabled="disabledInput.school"
          :loading="isFetchingSchool"
          :clearable="school.id !== null"
          @input="onInput($event, 'school')"
        >
          <template #no-options>
            {{ `ไม่พบข้อมูล${labelSchool}` }}
          </template>
        </v-select>

        <small
          v-if="
            invalid &&
            validation.filterMaster.mSchoolId &&
            !validation.filterMaster.mSchoolId.required
          "
          class="text-danger"
        >
          กรุณาเลือก{{ labelSchool }}
        </small>
      </div>

      <slot name="beforeSchoolClass"></slot>

      <!-- <div
        v-if="!hiddenInput.schoolClass"
        class="col-12 px-2 mb-2"
        :class="{ [`col-lg-${colSchoolClass}`]: true }"
      >
        <label class="font-weight-bold ft-s-14" for="schoolClass">{{
          labelSchoolClass
        }}</label>

        <v-select
          id="schoolClass"
          v-model="schoolClass"
          :options="classes"
          :disabled="disabledInput.schoolClass"
          :loading="isFetchingSchoolClass"
          :clearable="schoolClass.id !== null"
          @input="onInput($event, 'schoolClass')"
        >
          <template #no-options>
            {{ `ไม่พบข้อมูล${labelSchoolClass}` }}
          </template>
        </v-select>

        <small
          v-if="
            invalid &&
            validation.filterMaster.mClassId &&
            !validation.filterMaster.mClassId.required
          "
          class="text-danger"
        >
          กรุณาเลือก{{ labelSchoolClass }}
        </small>
      </div>

      <div
        v-if="!hiddenInput.room"
        class="col-12 px-2 mb-2"
        :class="{ [`col-lg-${colRoom}`]: true }"
      >
        <label class="font-weight-bold ft-s-14" for="room">{{
          labelRoom
        }}</label>

        <v-select
          id="room"
          v-model="room"
          :options="rooms"
          :disabled="disabledInput.room"
          :clearable="room.id !== null"
          @input="onInput($event, 'room')"
        >
          <template #no-options>
            {{ `ไม่พบข้อมูล${labelRoom}` }}
          </template>
        </v-select>

        <small
          v-if="
            invalid &&
            validation.filterMaster.room &&
            !validation.filterMaster.room.required
          "
          class="text-danger"
        >
          กรุณาเลือก{{ labelRoom }}
        </small>
      </div> -->

      <slot></slot>
    </div>
  </b-form>
</template>

<script>
import { debounce } from "lodash";
import vSelect from "vue-select";
import {
  MClass,
  MDivision,
  MHeadquarter,
  MSchool,
  MStation,
} from "../../models";

export default {
  props: {
    formData: Object,
    hiddenInput: {
      type: Object,
      default: () => ({}),
    },
    mHeadquarterId: {
      type: [String, Number],
      default: null,
    },
    mDivisionId: {
      type: [String, Number],
      default: null,
    },
    mStationId: {
      type: [String, Number],
      default: null,
    },
    mSchoolId: {
      type: [String, Number],
      default: null,
    },
    mClassId: {
      type: [String, Number],
      default: null,
    },
    mRoom: {
      type: [String, Number],
      default: null,
    },
    colHeadquarter: {
      type: [String, Number],
      default: 4,
    },
    colDivision: {
      type: [String, Number],
      default: 4,
    },
    colStation: {
      type: [String, Number],
      default: 4,
    },
    colSchool: {
      type: [String, Number],
      default: 4,
    },
    colSchoolClass: {
      type: [String, Number],
      default: 4,
    },
    colRoom: {
      type: [String, Number],
      default: 4,
    },
    labelHeadquarter: {
      type: String,
      default: "ภาค",
    },
    labelDivision: {
      type: String,
      default: "จังหวัด",
    },
    labelStation: {
      type: String,
      default: "สถานี",
    },
    labelSchool: {
      type: String,
      default: "โรงเรียน",
    },
    labelSchoolClass: {
      type: String,
      default: "ชั้นเรียน",
    },
    labelRoom: {
      type: String,
      default: "ห้องเรียน",
    },
    disabledInput: {
      type: Object,
      default: () => ({}),
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    validation: {
      type: Object,
    },
    defaultId: {
      type: [Number, String],
      default: null,
    },
    defaultLabel: {
      type: String,
      default: "ทั้งหมด",
    },
    maximumRoom: {
      type: Number,
      default: 20,
    },
  },

  model: {
    prop: "formData",
    event: "change",
  },

  components: {
    "v-select": vSelect,
  },

  data() {
    return {
      isFetchingHeadQuarter: false,
      isFetchingDivision: false,
      isFetchingStation: false,
      isFetchingSchool: false,
      isFetchingSchoolClass: false,
      isInitHeadQuarter: false,
      isInitDivision: false,
      isInitStation: false,
      isInitClass: false,
      headQuarter: { id: this.defaultId, label: this.defaultLabel },
      division: { id: this.defaultId, label: this.defaultLabel },
      station: { id: this.defaultId, label: this.defaultLabel },
      school: { id: this.defaultId, label: this.defaultLabel },
      schoolClass: { id: this.defaultId, label: this.defaultLabel },
      room: { id: this.defaultId, label: this.defaultLabel },
      form: {
        mHeadquarterId: this.mHeadquarterId || null,
        mDivisionId: this.mDivisionId || null,
        mStationId: this.mStationId || null,
        mSchoolId: this.mSchoolId || null,
        mClassId: this.mClassId || null,
        room: this.mRoom || null,
      },
    };
  },

  watch: {
    form: {
      deep: true,
      immediate: true,
      handler: "syncData",
    },

    headQuarter: {
      handler: "onHeadQuarterChanged",
    },

    division: {
      handler: "onDivisionChanged",
    },

    station: {
      handler: "onStationChanged",
    },

    school: {
      handler: "onSchoolChanged",
    },

    schoolClass: {
      handler: "onSchoolClassChanged",
    },

    headQuarterDivision: {
      handler: "onHeadQuarterDivisionChanged",
    },

    room: {
      handler: "onRoomChanged",
    },
  },

  computed: {
    headQuarterDivision() {
      const { headQuarter, division } = this;

      return { headQuarter, division };
    },

    headQuarters() {
      return [
        { id: this.defaultId, label: this.defaultLabel },
        ...MHeadquarter.all().map((record) => ({
          id: record.id,
          label: record.m_headquarters_name,
        })),
      ];
    },

    divisions() {
      return this.headQuarter
        ? [
            { id: this.defaultId, label: this.defaultLabel },
            ...MDivision.query()
              .where("mHeadquarterId", this.headQuarter.id)
              .get()
              .map((record) => ({
                id: record.id,
                label: record.m_division_name,
              })),
          ]
        : [];
    },

    stations() {
      return this.division
        ? [
            { id: this.defaultId, label: this.defaultLabel },
            ...MStation.query()
              .where("mDivisionId", this.division.id)
              .get()
              .map((record) => ({
                id: record.id,
                label: record.m_station_name,
              })),
          ]
        : [];
    },

    schools() {
      const query = MSchool.query();

      if (this.headQuarter) {
        query.where("mHeadquarterId", this.headQuarter.id);
      }

      if (this.division) {
        query.where("mDivisionId", this.division.id);
      }

      let data = query.get();

      if (data && data.length) {
        data = [
          { id: this.defaultId, label: this.defaultLabel },
          ...data.map((record) => ({
            id: record.id,
            label: record.m_school_name,
          })),
        ];
      }

      return data;
    },

    classes() {
      return [
        { id: this.defaultId, label: this.defaultLabel },
        ...MClass.all().map((record) => ({
          id: record.id,
          label: record.m_class_name,
        })),
      ];
    },

    rooms() {
      const rooms = [{ id: this.defaultId, label: this.defaultLabel }];

      for (let roomNumber = 1; roomNumber <= this.maximumRoom; roomNumber++) {
        rooms.push({ id: roomNumber, label: roomNumber });
      }

      return rooms;
    },
  },

  methods: {
    async init() {
      await this.fetchHeadQuarter();

      if (this.mHeadquarterId && !this.isInitHeadQuarter) {
        const mHeadquarter = MHeadquarter.find(this.mHeadquarterId);

        if (mHeadquarter) {
          this.headQuarter = {
            id: mHeadquarter.id,
            label: mHeadquarter.m_headquarters_name,
          };
        }

        this.isInitHeadQuarter = true;
      } else {
        this.headQuarter = { id: this.defaultId, label: this.defaultLabel };
      }

      await this.fetchClass();

      if (this.mClassId && !this.isInitClass) {
        const mClass = MClass.find(this.mClassId);

        if (mClass) {
          this.schoolClass = {
            id: mClass.id,
            label: mClass.m_class_name,
          };
        }

        this.isInitClass = true;
      } else {
        this.schoolClass = { id: this.defaultId, label: this.defaultLabel };
      }
    },

    async fetchHeadQuarter() {
      this.isFetchingHeadQuarter = true;
      try {
        await MHeadquarter.api().findAll();
      } catch (error) {
        console.error(error);
        this.$toast.error("ไม่สามารถดึงข้อมูลภาคได้ กรุณาลองใหม่อีกครั้ง");
      } finally {
        this.isFetchingHeadQuarter = false;
      }
    },

    async onHeadQuarterChanged(headQuarter) {
      let id = null;

      if (headQuarter && headQuarter.id) {
        id = headQuarter.id;

        this.isFetchingDivision = true;

        try {
          await MDivision.api().findAll({
            mHeadquarterId: headQuarter.id,
          });
        } catch (error) {
          console.error(error);
          this.$toast.error(
            "ไม่สามารถดึงข้อมูลจังหวัดได้ กรุณาลองใหม่อีกครั้ง"
          );
        } finally {
          this.isFetchingDivision = false;
        }
      }

      if (this.mDivisionId && !this.isInitDivision) {
        const mDivision = MDivision.find(this.mDivisionId);

        if (mDivision) {
          this.division = {
            id: mDivision.id,
            label: mDivision.m_division_name,
          };
        }

        this.isInitDivision = true;
      } else {
        this.division = { id: this.defaultId, label: this.defaultLabel };
      }

      this.$set(this.form, "mHeadquarterId", id);
    },

    async onDivisionChanged(division) {
      let id = null;

      if (division && division.id) {
        id = division.id;

        this.isFetchingStation = true;

        try {
          await MStation.api().findAll({
            mDivisionId: division.id,
          });
        } catch (error) {
          console.error(error);
          this.$toast.error("ไม่สามารถดึงข้อมูลสถานีได้ กรุณาลองใหม่อีกครั้ง");
        } finally {
          this.isFetchingStation = false;
        }
      }

      if (this.mStationId && !this.isInitStation) {
        const mStation = MStation.find(this.mStationId);

        if (mStation) {
          this.station = {
            id: mStation.id,
            label: mStation.m_station_name,
          };
        }

        this.isInitStation = true;
      } else {
        this.station = { id: this.defaultId, label: this.defaultLabel };
      }

      this.$set(this.form, "mDivisionId", id);
    },

    onHeadQuarterDivisionChanged(headQuarterDivision = null) {
      this.handleHeadQuarterDivisionChanged(headQuarterDivision);
    },

    async handleHeadQuarterDivisionChanged(headQuarterDivision = null) {
      if (this.hiddenInput && this.hiddenInput.school) return;

      const params = {};

      MSchool.deleteAll();

      if (headQuarterDivision) {
        const { headQuarter, division } = headQuarterDivision;

        if (!headQuarter.id && !division.id) {
          return;
        }

        if (headQuarter && headQuarter.id) {
          this.$set(params, "mHeadquarterId", headQuarter.id);
        }

        if (division && division.id) {
          this.$set(params, "mDivisionId", division.id);
        }
      }

      await this.fetchSchool(params);

      if (this.mSchoolId && !this.school) {
        const mSchool = MSchool.find(this.mSchoolId);

        if (mSchool) {
          this.school = {
            id: mSchool.id,
            label: mSchool.m_school_name,
          };
        }
      } else {
        this.school = { id: this.defaultId, label: this.defaultLabel };
      }
    },

    async fetchSchool(params = {}) {
      let promise;

      this.isFetchingSchool = true;

      try {
        promise = await MSchool.api().findAll(params);
      } catch (error) {
        console.error(error);
        this.$toast.error("ไม่สามารถดึงข้อมูลโรงเรียนได้ กรุณาลองใหม่อีกครั้ง");
      } finally {
        this.isFetchingSchool = false;
      }

      return promise;
    },

    async onStationChanged(station = null) {
      let id = null;

      if (station && station.id) {
        id = station.id;
      }

      this.$set(this.form, "mStationId", id);
    },

    async onSchoolChanged(school = null) {
      let id = null;

      if (school && school.id) {
        id = school.id;
      }

      this.$set(this.form, "mSchoolId", id);
    },

    async fetchClass() {
      this.isFetchingSchoolClass = true;

      try {
        await MClass.api().findAll();
      } catch (error) {
        console.error(error);
        this.$toast.error(
          "ไม่สามารถดึงข้อมูลชั้นเรียนได้ กรุณาลองใหม่อีกครั้ง"
        );
      } finally {
        this.isFetchingSchoolClass = false;
      }
    },

    onSchoolClassChanged(schoolClass = null) {
      let id = null;

      if (schoolClass && schoolClass.id) {
        id = schoolClass.id;
        // room = schoolClass.label;
      }

      this.$set(this.form, "mClassId", id);
      // this.$set(this.form, "room", room);
    },

    onRoomChanged(room = null) {
      let value = null;

      if (room && room.id) {
        value = room.id;
      }

      this.$set(this.form, "room", value);
    },

    syncData(v) {
      this.$emit("change", v);
    },

    onInput(value, dataName) {
      if (!value) {
        this[dataName] = { id: this.defaultId, label: this.defaultLabel };
      }
    },
  },

  mounted() {
    this.handleHeadQuarterDivisionChanged = debounce(
      this.handleHeadQuarterDivisionChanged,
      500
    );

    this.init();
  },
};
</script>
